<template>
  <div id="login_page">
    <p class="tips">如遇问题请拨打客服热线：<span class="color1">400-821-5247</span></p>
    <div class="login_box">
      <p class="login_title">四季教育学习管理中心</p>
      <div class="form_box">
        <p>修改密码</p>
        <el-form :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-width="0"
                class="login_form"
                label-position="left">
          <el-form-item  prop="pwd">
            <el-input type="password"
                      v-model="ruleForm.pwd"
                      clearable placeholder="新密码"></el-input>
          </el-form-item>
          <el-form-item  prop="checkPwd">
            <el-input type="password"
                      v-model="ruleForm.checkPwd"
                      clearable placeholder="再次确认新密码"></el-input>
          </el-form-item>
          <el-form-item>
                <el-button class="submit_button"
                  type="primary"
                  @click="submitForm('ruleForm')"
                  :loading="loading">确认修改</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import md5 from 'js-md5';
import { passwordValidate } from '@/utils/util'
import { edit_pwd,get_zifuchuan } from '@/api/user'
export default {
  name: 'changepsw',
  components: {
  },
  data () {
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.pwd) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      username: '',
      telphone: '',
      userid: '',
      ruleForm: {
        pwd: '',
        checkPwd: ''
      },
      rules: {
        pwd: passwordValidate,
        checkPwd: [
          { required: true, message: '请输入您的密码', trigger: 'blur' },
          { validator: validatePass2, trigger: 'blur' }
        ],
      },
      loading: false,
      character:0
    };
  },
  created () {
    let {id } = this.$store.state.user.stu_user_info[0]
    this.userid = id
    this.getzifuchuan()
  },
  methods: {
    //获取随机数
    async getzifuchuan () {
      let res = await get_zifuchuan({length:20});
      if (res.flag == '1') {
        var character = res.datainfo.character;
        this.character =character;
      } else if(res.flag =='-1'){
        this.$store.dispatch("logout",{
          tips: '登录失效,请重新登录'
        });
      } else {
        this.$alert(res.msg, '提示', {
          confirmButtonText: '确定',
          showClose: false,
          type: 'error',
        });
      }
    },
    /**提交 */
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.subfn();
        }
      });
    },
    async subfn () {
      let prames = {
        userid: this.userid,
        pwd: md5(this.ruleForm.pwd),
        character:this.character
      }
      this.loading = true;
      let res = await edit_pwd(prames)
      if (res.flag == '1') {
        this.$store.commit('setUserInfo', JSON.stringify(res.datainfo));
        this.$message({
          message: '修改成功，请登录',
          type: 'success'
        });
        setTimeout(() => {
          this.$router.push('/class')
        }, 1000)
        this.loading = false
      }else if(res.flag =='-1'){
        this.$store.dispatch("logout",{
          tips: '登录失效,请重新登录'
        });
      } else {
        this.$alert(res.msg, '提示', {
          confirmButtonText: '确定',
          type: 'error',
        });
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
@import "login.scss";
</style>
